
import { defineComponent, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import useTaskOverview from "@/hooks/rv/communication/useTaskOverview";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const router = useRouter();
    const {
      query,
      taskPeriod,
      dateFormat,
      column,
      data,
      fetchTableData,
      statusList,
      categoryList,
    } = useTaskOverview();
    const search = () => {
      fetchTableData();
    };
    const init = () => {
      search();
    };
    init();

    const handleToDetail = (record: any) => {
      router.push({
        path: "/retailValidation/taskOverview/detail",
        query: {
          id: record.id,
          category: record.category
        }
      });
    };

    return {
      ...toRefs(query),
      taskPeriod,
      dateFormat,
      column,
      data,
      search,
      handleToDetail,
      statusList,
      categoryList,
      isPagination: false,
    };
  },
  components: {
    TableWrapper,
  },
});
